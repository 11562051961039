import * as React from "react"
import { useState } from 'react';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Modal from "../../components/Modal"
import { Heart, LifeBuoy, MessageCircle, Mic, Search, TrendingUp, Book } from "react-feather"
import { DollarSign, ThumbsUp, Zap, BookOpen, Clipboard, PieChart, Activity, CheckSquare } from 'react-feather';

const Organization = () => {

    const [showModal, setShowModal] = useState(null);



    return (
        <Layout>
            <SEO title="Organization" />

            <div className="content-wrapper">
                <div className="page-intro pb-4">
                    <h1 className="mb-3 h2">Organisational Restorative Practice</h1>
                    <p>Implementing a restorative approach inside your organisation can have significant impact on employee moral and churn. We have developed a 3 step approach that will maximize success as you transition to a restorative organisation</p>
                </div>
            </div>
            <div className="container">
                <ul className="org">
                    <li className="org__item">
                        <div className="org__side">
                            <div className="d-flex flex-grow-1 flex-lg-grow-0 align-items-center align-items-lg-start">
                                <div className="org__side__no">1</div>
                                <h4 className="lead mb-0"><b>Commit</b> Organisational Readiness</h4>
                            </div>
                            <button type="button" onClick={() => setShowModal('readiness')} className="org__side__help">
                                We will help you get to readiness
                            </button>
                        </div>

                        <div className="org__main">
                            <div className="org__main__content">
                                <div className="card">
                                    <div className="card-body d-flex align-items-start p-3">
                                        <div className="org__main__icon"><Search size={30} /></div>
                                        <div>
                                            <h4 className="lead bold text-accent mb-1">Infrastructure Analysis</h4>
                                            <p>It is important to identify the support structures you have in HR for employee communications so that any needed updates can by incorporated before you deploy a restorative approach</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body d-flex align-items-start p-3">
                                        <div className="org__main__icon"><Mic size={30} /></div>
                                        <div>
                                            <h4 className="lead bold text-accent mb-1">Organisation Communications</h4>
                                            <p>A top-down commitment to a Restorative culture should permeate into all internal processes which begins with a firm commitment by management.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="org__main__arrow"></div>
                        </div>
                    </li>

                    <li className="org__item">
                        <div className="org__side">
                            <div className="d-flex flex-grow-1 flex-lg-grow-0 align-items-center align-items-lg-start">
                                <div className="org__side__no">2</div>
                                <h4 className="lead mb-0"><b>Communicate</b>  Guidance & Principles</h4>
                            </div>
                            <button type="button" onClick={() => setShowModal('maximise')} className="org__side__help">
                                We will help you maximise the impact
                            </button>
                        </div>

                        <div className="org__main">
                            <div className="org__main__content">
                                <div className="card">
                                    <div className="card-body d-flex align-items-start p-3">
                                        <div className="org__main__icon"><TrendingUp size={30} /></div>
                                        <div>
                                            <h4 className="lead bold text-accent">Restorative Organisations Benefits</h4>
                                            <p>All stakeholders need to understand the benefits for them and see examples of a restorative approach</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body d-flex align-items-start p-3">
                                        <div className="org__main__icon"><LifeBuoy size={30} /></div>
                                        <div>
                                            <h4 className="lead bold text-accent">Help & Support</h4>
                                            <p>All stakeholders should be provided with training and communications on how to absorb restorative principles into their day–to-day activity.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="org__main__arrow"></div>
                        </div>
                    </li>

                    <li className="org__item org__item--last">
                        <div className="org__side">
                            <div className="d-flex flex-grow-1 flex-lg-grow-0 align-items-center align-items-lg-start">
                                <div className="org__side__no">3</div>
                                <h4 className="lead mb-0"><b>Support</b>  Tools & Process</h4>
                            </div>
                            <div className="org__side__phone">
                                <StaticImage width={240} src="../../images/solutions/app_phone_01.png" alt='' />
                            </div>
                        </div>

                        <div className="org__main">
                            <div className="org__main__content org__main__content--extra">
                                <div className="card">
                                    <div className="card-body d-flex align-items-start p-3">
                                        <div className="org__main__icon"><Book size={30} /></div>
                                        <div>
                                            <h4 className="lead bold text-accent mb-1">Restorative Mentoring </h4>
                                            <p>Pre-emptive one-to-one Restorative Mentoring for vulnerable individuals that will help avoid issues before they arise and will instill a Restorative Culture in any organisation or community</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body d-flex align-items-start p-3">
                                        <div className="org__main__icon"><MessageCircle size={30} /></div>
                                        <div>
                                            <h4 className="lead bold text-accent mb-1">Restorative Mediation</h4>
                                            <p>Once an issue has been identified but no party takes responsibility for a process of Restorative Mediation can bring that issue to concluion and improve the satisfaction of all involved.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body d-flex align-items-start p-3">
                                        <div className="org__main__icon"><Heart size={30} /></div>
                                        <div>
                                            <h4 className="lead bold text-accent mb-1"> Restorative Justice</h4>
                                            <p>If an issue has a clear person who has harmed a process of Restorativ Justice will see a reduction in repeat behaviour and the person harmed will feel more closure.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="org__main__base">
                                <div className="org__main__base__item">Avoidance</div>
                                <div className="org__main__base__item">Conflict Resolution and Restoration</div>

                            </div>

                        </div>
                    </li>
                </ul>
            </div>



            {/* <!-- copied from Benefits page --> */}
            <div className="content-wrapper">

                <div className="container-fluid">
                    <div className="page-intro">
                        <h1 className="h3">Implementing a Restorative approach in your organisation will see long term cultural change and reduce employment costs as you create a community that cares.</h1>
                        <p className='lead'>We combine three strategies to assist large and small organisations rebuild relationships</p>
                    </div>
                </div>

                <div className="row home-solutions">
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <h3>Restorativ Practice</h3>
                                <div className="home-solutions__img">
                                    <StaticImage src="../../images/homepage/icon_app.png" alt='Phone app' />
                                </div>
                                <p className='mb-3'>Putting the Power of Restorative Justice in your hands</p>
                                {/* <AnchorLink stripHash to='/solutions#solution-app' className='btn'>Find out more</AnchorLink> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <h3>Restorativ Mediation</h3>
                                <div className="home-solutions__img">
                                    <StaticImage src="../../images/homepage/icon_central.png" alt='Phone app' />
                                </div>
                                <p className='mb-3'>Finding common ground via technology</p>
                                {/* <AnchorLink stripHash to='/solutions#solution-central' className='btn'>Find out more</AnchorLink> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <h3> Restorativ Mentoring</h3>
                                <div className="home-solutions__img">
                                    <StaticImage src="../../images/homepage/icon_consulting.png" alt='Phone app' />
                                </div>
                                <p className='mb-3'>Positive influence captured via our App</p>
                                {/* <AnchorLink stripHash to='/solutions#solution-consulting' className='btn'>Find out more</AnchorLink> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="section-primary">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 mx-auto text-center">
                                <h4>Restorativ App is a mobile-only solution that can be deployed in minutes and provides the following benefits:</h4>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-sm-6 col-md-4 mb-2 d-flex">
                                <div className="card">
                                    <div className="card-body">
                                        <DollarSign size="50" />
                                        <h4 className='mb-1 mt-2'>Reduced technology costs</h4>
                                        <p className='small mb-0'>A solution that can be accessed by anyone with a smartphone</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-4 mb-2 d-flex">
                                <div className="card">
                                    <div className="card-body">
                                        <ThumbsUp size="50" />
                                        <h4 className='mb-1 mt-2'>Intuitive</h4>
                                        <p className='small mb-0'>A solution that anyone can use via a  chat room interface</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 mb-2 d-flex">
                                <div className="card">
                                    <div className="card-body px-2">
                                        <Zap size="50" />
                                        <h4 className='mb-1 mt-2'>Empowering</h4>
                                        <p className='small mb-0'>A solution that brings the Affected Party into a safe and supportive environment</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 mb-2 d-flex">
                                <div className="card">
                                    <div className="card-body px-2">
                                        <Search size="50" />
                                        <h4 className='mb-1 mt-2'>Predictive</h4>
                                        <p className='small mb-0'>A solution that provides a predictive assessment for the most effective path to restoring the relationship</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-4 mb-2 d-flex">
                                <div className="card">
                                    <div className="card-body px-2">
                                        <BookOpen size="50" />
                                        <h4 className='mb-1 mt-2'>Learning</h4>
                                        <p className='small mb-0'>A solution that facilitates the most efficient learning process for the Responsible Party using predictive technology</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 mb-2 d-flex">
                                <div className="card">
                                    <div className="card-body">
                                        <Clipboard size="50" />
                                        <h4 className='mb-1 mt-2'>Reporting</h4>
                                        <p className='small mb-0'>A solution with a confidential audit trail of RJ sessions for future reference</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-white">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 mx-auto text-center mb-4">
                                <h3>Restorativ Central is a fully integrated online application that manages Restorative processes for large organisations:</h3>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-md-8 mx-auto">
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <div className="card">
                                            <div className="card-body">
                                                <PieChart size="50" />
                                                <h4 className='mb-1 mt-2'>Data analysis</h4>
                                                <p className='small mb-0'>Monitor and analyse RJ sessions</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="card">
                                            <div className="card-body">
                                                <Activity size="50" />
                                                <h4 className='mb-1 mt-2'>Project management</h4>
                                                <p className='small mb-0'>For large complex RJ circles</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="section-accent">
                <div className="content-wrapper">
                    <div className="container-fluid py-3">
                        <h4 className='text-center mb-4'>Restorativ Consulting provides organisations two key benefits:</h4>
                        <div className="row text-center">
                            <div className="col-md-8 mx-auto text-body-color">
                                <div className="row">
                                    <div className="col-md-6 mb-2 d-flex">
                                        <div className="card">
                                            <div className="card-body px-2">
                                                <CheckSquare size="50" />
                                                <h4 className='mb-1 mt-2'>A Readiness Assessment</h4>
                                                <p className='small mb-0'>Restorativ provides both an assessment and a roadmap to RJ implementation.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2 d-flex">
                                        <div className="card">
                                            <div className="card-body px-2">
                                                <LifeBuoy size="50" />
                                                <h4 className='mb-1 mt-2'>Implementation support</h4>
                                                <p className='small mb-0'>Providing support for the implementation of restorative practices and the use of Restorativ in the organization, speeding up the time to benefit.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <Modal closeModal={() => setShowModal(null)}>
                    {showModal === 'readiness' ?
                        <div className="p-3">
                            <h3 className="mb-2">Readiness Assessment</h3>
                            <p className=" lead mb-1">Our team can deliver a One week assessment of:</p>
                            <ul className="list-bullet">
                                <li>HR Readiness</li>
                                <li>Needed Processes</li>
                                <li>Suggested Communications Strategy</li>
                                <li>Project Plan</li>
                            </ul>
                            <Link to='/contact' className="btn">Contact Restorativ</Link>
                        </div>
                        : <div className="p-3">
                            <h3 className="mb-2">Impact Reporting</h3>
                            <p className=" lead mb-1">Our Solution includes real time reporting and an audit trail:</p>
                            <ul className="list-bullet">
                                <li>Issues resolved</li>
                                <li>Active cases</li>
                                <li>Types of cases</li>
                                <li>Time Impact</li>
                                <li>Organisational Empathy Score</li>
                            </ul>
                            <Link to='/contact' className="btn">Contact Restorativ</Link>
                        </div>
                    }

                </Modal>
            )}

        </Layout>
    )
}
export default Organization;